import { useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Spacing } from "@residently/components/tokens";
import { useRouter } from "next/router";
import { useEffect } from "react";

import LoginLayout from "components/layouts/LoginLayout";

import I18n from "services/I18n";

const StyledLoginLayout = styled(LoginLayout)({
  display: "flex",
  justifyContent: "center",
});

export default function Index() {
  const { isLoading, isAuthenticated } = useAuth0();

  const router = useRouter();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      router.push("/login");
      return;
    }

    if (!isLoading && isAuthenticated) {
      router.push("/dashboard");
    }
  }, [router, isLoading, isAuthenticated]);

  return (
    <StyledLoginLayout>
      <Typography variant="h2" align="center">
        {I18n.t("login.complete.loading")}
      </Typography>

      <Box mt={Spacing.medium} textAlign="center">
        <CircularProgress color="primary" />
      </Box>
    </StyledLoginLayout>
  );
}
