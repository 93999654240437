import Head from "next/head";

export interface Props {
  title: string;
}

const MetaHead = ({ title }: Props) => (
  <Head>
    <title>{title}</title>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
    <link rel="icon" href="/favicon.ico" />
  </Head>
);

export default MetaHead;
