import {
  Box,
  Skeleton,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import NextLink from "next/link";

export interface Props {
  loading?: boolean;
  href: string;
  text: string;
  variant?: Variant;
  color?: TypographyProps["color"];
  display?: TypographyProps["display"];
  prefixIcon?: any;
  postfixIcon?: any;
  skeletonWidth?: number;
  sx?: SxProps;
  className?: string;
  newTab?: boolean;
}

const Link = ({
  loading = false,
  href,
  text,
  variant = "body2",
  color = "secondary",
  display = "block",
  prefixIcon,
  postfixIcon,
  skeletonWidth = 45,
  sx,
  className,
  newTab = false,
}: Props) => (
  <Box sx={sx} className={className}>
    <Typography variant={variant} color={color} display={display}>
      {loading && <Skeleton variant="text" width={skeletonWidth} />}
      <NextLink href={href} passHref target={newTab ? "_blank" : undefined}>
        {!loading && (
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {prefixIcon} {text} {postfixIcon}
          </span>
        )}
      </NextLink>
    </Typography>
  </Box>
);

export default Link;
