import { Container, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Spacing, ThemeSpacing } from "@residently/components/tokens";
import { PropsWithChildren } from "react";

import MetaHead from "components/layouts/MetaHead";

import Link from "components/atoms/Link";

import I18n from "services/I18n";

const Logo = styled("div")({
  marginBottom: Spacing.xxxlarge,
  textAlign: "center",
});

const Footer = styled("footer")({
  marginTop: "auto",
  paddingTop: Spacing.xxlarge,
  textAlign: "center",
});

const LoginLayout = ({ children }: PropsWithChildren) => (
  <Container>
    <MetaHead title={I18n.t("appName")} />
    <Stack sx={{ paddingY: Spacing.xxlarge, minHeight: "100vh" }}>
      <Logo>
        <img
          src="https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/logo/2021/SVG/Residently_Wordmark_logo_Black_RGB.svg"
          height="30"
          alt="Residently logo"
        />
      </Logo>

      <Stack width="330px" spacing={ThemeSpacing.xlarge} marginX="auto">
        {children}
      </Stack>

      <Footer>
        <Typography variant="body2" color="white.white900">
          {I18n.t("login.help")}
        </Typography>
        <Link
          href={`mailto:${I18n.t("supportEmail")}`}
          text={I18n.t("supportEmail")}
          display="inline"
          variant="body2"
        />
      </Footer>
    </Stack>
  </Container>
);

export default LoginLayout;
